import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgHash from 'lightgallery/plugins/hash';
import lgShare from 'lightgallery/plugins/share';
import Splide from '@splidejs/splide';

export default {
  init() {
    // thumbnail gallery
    lightGallery(document.getElementById('locationGallery'), {
      plugins: [lgZoom, lgThumbnail, lgHash, lgShare],
      thumbnail: true,
    });

    // Location explore line draw
    const config = {
      target: $('.point'),
      line: $('.line'),
      delay: 0, // enter zero for live resizing
    };
    const drawBetweenObjects = {
      //cmake the line
      makeLine: function (line, div1, div2) {
        var className = div1.attr('id') + div2.attr('id');
        if (className.includes('undefined') !== true) {
          //error check
          $(line)
            .clone()
            .addClass('deleteMe')
            .addClass(className)
            .removeClass('original')
            .insertAfter(line);
          //calculations (for legibility, these are separte vars)
          var x1 = div1.offset().left + div1.width() / 2;
          var y1 = div1.offset().top + div1.height() / 2;
          var x2 = div2.offset().left + div2.width() / 2;
          var y2 = div2.offset().top + div2.height() / 2;
          $('.' + className)
            .attr('x1', x1)
            .attr('y1', y1)
            .attr('x2', x2)
            .attr('y2', y2); //svg attributes
        } else {
          console.error('undefined object');
        }
      },
      findLines: function (search) {
        $('.deleteMe').remove(); //remove last set of lines
        $(search).each(function (index) {
          if (search.eq(index + 1).length) {
            //only do drawBetweenObject if there is another.
            drawBetweenObjects.makeLine(config.line, $(this), search.eq(index + 1)); //args order - line, div1 and div2 - the next div.
          }
        });
      },
      init: function () {
        drawBetweenObjects.findLines(config.target);
        //give resizing time to happen
        var resizeId;
        $(window).resize(function () {
          clearTimeout(resizeId);
          if (config.delay !== 0) {
            resizeId = setTimeout(doneResizing, config.delay);
          } else {
            drawBetweenObjects.findLines(config.target);
          }
        });
        function doneResizing() {
          drawBetweenObjects.findLines(config.target);
        }
      },
    };

    drawBetweenObjects.init();

    $('#exploreBtn').click(function () {
      drawBetweenObjects.findLines(config.target);
    });

    /*
     ** Location Reviews Slider
     */

    // Loop through each instance of a slider and initialize based on the matching class
    var locationSlider = document.getElementById('locationReviews');
    if (locationSlider) {
      var splide = new Splide('.location-reviews-slider', {
        type: 'loop',
        perPage: 1,
        arrows: true,
        speed: 500,
        pagination: false,
      });
      splide.mount();
    }

    /*
     ** Location Photo Gallery Slider
     */

    // Loop through each instance of a slider and initialize based on the matching class
    var photoSlider = document.getElementById('locationPhotos');
    if (photoSlider) {
      var splidePhotos = new Splide('.location-photo-slider', {
        type: 'loop',
        perPage: 1,
        perMove: 1,
        arrows: true,
        speed: 500,
        cover: true,
        pagination: false,
      });
      splidePhotos.mount();
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
